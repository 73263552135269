* {
  font-family: 'Roboto';
}

/*my name jeff*/
.App {
  text-align: center;
  background-image: url('./images/background.jpeg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.App hr {
  border: 2px solid #000;
  margin: 0 10px;
}

.App .image-div {
  display: inline-block;
}

.App img {
  width: 300px;
}
