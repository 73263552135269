.Header {
  background: #2196f3;
  height: 60px;
  position: sticky;
  top: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Header p {
  font-weight: bold;
  font-size: 32px;
}
