.Footer {
  background: #2196f3;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Footer p {
  font-weight: bold;
}
